import * as THREE from "three";
import * as ARCH from "@inst-aaa/archiweb-core"

let viewport, scene;

let handle = [];

let curve, tangent = [];
let center, left, right;
let curo;


const param = {
  count: 50,
  background: 0xfafafa,
}

function initGUI() {
  viewport.gui.addColor(param, 'background').onChange(() => {
    scene.background = new THREE.Color(param.background);
    
  });
  viewport.gui.add(param, 'count', 20, 100, 5).onChange(() => {
    tangent.forEach(t => {
      viewport.removeObject(t);
    })
    
    for (let i = 0; i <= param.count; i++) {
      tangent[i] = new ARCH.Segments(viewport, {
        closed: false,
        lineMaterial: new THREE.LineBasicMaterial({color: 0x00ff00})
      });
    }
    updateCurve();
  });
}

function initScene() {
  scene.background = new THREE.Color(0xfafafa);
  tangent = [];
  handle = [];
  const light = new THREE.SpotLight(0xffffff, 1.5);
  light.position.set(0, 0, 1000);
  scene.add(light);
  
  let pos = [[-10, 30], [0, 10], [30, 10], [40, -30], [50, -50]];
  for (let p of pos) {
    handle.push(new ARCH.Cylinder(viewport, p, [1, 1], {material: new THREE.MeshLambertMaterial({color: 0xff0000})}));
  }
  
  handle.forEach(h => viewport.addObjectLayer(h, 'handle'));
  viewport.changeLayer('handle');
  
  curve = new THREE.CatmullRomCurve3(handle.map((handle) => handle.position));
  curve.curveType = "catmullrom";
  
  const points = curve.getPoints(50);
  for (let i = 0; i <= param.count; i++) {
    tangent[i] = new ARCH.Segments(viewport, {
      closed: false,
      lineMaterial: new THREE.LineBasicMaterial({color: 0x00ff00})
    });
  }
  
  center = new ARCH.Segments(viewport, {points: points, closed: false});
  left = new ARCH.Segments(viewport, {closed: false, lineMaterial: new THREE.LineBasicMaterial({color: 0xff0000})});
  right = new ARCH.Segments(viewport, {closed: false, lineMaterial: new THREE.LineBasicMaterial({color: 0x0000ff})});
  
  updateCurve();
  
}

function updateCurve() {
  const l = [];
  const r = [];
  for (let i = 0; i <= param.count; i++) {
    // console.log(i);
    const t = i * (1. / param.count);
    let point = curve.getPointAt(t);
    let tangentAt = curve.getTangentAt(t);
    
    let rotWorldMatrix = new THREE.Matrix4();      //创建一个4*4矩阵
    rotWorldMatrix.makeRotationZ(90 * Math.PI / 180);//将矩阵设立为绕z轴顺时针90度
    tangentAt.applyMatrix4(rotWorldMatrix);
    
    //confirm the endpoints of every point scale
    let e1 = tangentAt.clone().multiplyScalar(3);
    let e2 = tangentAt.clone().multiplyScalar(-3);
    
    
    e1.add(point);
    e2.add(point);
    
    // console.log(e1);
    
    //draw the split line
    tangent[i].geometry.setFromPoints([e1, e2]);
    
    l.push(e1);
    r.push(e2);
    
  }
  
  let cv = new THREE.CatmullRomCurve3(l);
  left.setFromPoints(cv.getPoints(100));
  
  cv = new THREE.CatmullRomCurve3(r);
  right.setFromPoints(cv.getPoints(100));
}

function draw() {
  if (curo !== undefined) {
    curve = new THREE.CatmullRomCurve3(handle.map((handle) => handle.position));
    const points = curve.getPoints(500);
    center.setFromPoints(points);
    updateCurve();
  }
}

function initDrag() {
  viewport.drag.addEventListener('dragstart', (event) => {
    curo = event.object;
  })
  
  viewport.drag.addEventListener('dragend', () => {
    curo = undefined;
  })
}

function main() {
  viewport = new ARCH.Viewport('container/2d-editor', false, {drag: true, dimension: '2d', environment: false});
  
  viewport.camera.zoom = 7;
  scene = viewport.scene;
  viewport.draw = draw;
  initScene();
  initDrag();
  initGUI();
}

export {
  main
}
