/* eslint-disable no-unused-vars,no-case-declarations */
"use strict";
import * as THREE from 'three'
import * as ARCH from "@inst-aaa/archiweb-core"


let balls, segs;
let cubes, segs2;
let viewport;

function initScene() {
  balls = [];
  cubes = [];
  
  let mt = new ARCH.MaterialFactory();
  let points = [
    [-110, 460],
    [50, 500],
    [240, 410],
    [520, 640],
    [320, 940],
    [-190, 730]
  ]
  points.forEach((p) => balls.push(new ARCH.Cylinder(viewport, p, [5, 2], {
    material: mt.Flat(0xff0000),
    showEdge: true
  })));
  
  segs = new ARCH.Segments(viewport, {
    points: balls.map((handle) => handle.position),
    closed: true,
    filledMaterial: mt.Flat(0x993322),
    filled: true
  });
  
  points = [
    [-100, -100],
    [100, -100],
    [100, 100],
    [0, 200],
    [-100, 100]
  ]
  
  points.forEach((p) => cubes.push(new ARCH.Cuboid(viewport, p, [10, 10, 2], {material: mt.Flat(0x0000ff)})));
  segs2 = new ARCH.Segments(viewport, {
    points: cubes.map((handle) => handle.position),
    closed: true,
    filledMaterial: mt.Flat(0x223344),
    filled: true
  });
  
  balls.forEach((b) => {
    viewport.addObjectLayer(b, 'handle');
  })
  
  cubes.forEach((c) => {
    viewport.addObjectLayer(c, 'handle');
  })
  
  viewport.changeLayer('handle');
  
  
}

let curO = undefined;

function draw() {
  if (curO !== undefined) {
    
    if (balls.includes(curO))
      segs.setFromPoints((balls.map((handle) => handle.position)))
    if (cubes.includes(curO))
      segs2.setFromPoints((cubes.map((handle) => handle.position)))
    
  }
}

function initDrag() {
  viewport.drag.addEventListener('dragstart', (event) => {
    curO = event.object;
  })
  
  viewport.drag.addEventListener('drag', (event) => {
    event.object.position.z = 0;
  })
  
  viewport.drag.addEventListener('dragend', () => {
    curO = undefined;
  })
}

function main() {
  viewport = new ARCH.Viewport("container/polygon-handles", true, {drag: true});
  
  
  viewport.draw = draw;
  
  initScene();
  initDrag();
  
  
}

export {
  main
}
