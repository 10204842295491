import * as ARCH from "@inst-aaa/archiweb-core"
import {loaderOption} from "@inst-aaa/archiweb-core";
// import {loaderOption} from "@/archiweb"


let viewport;

/* ---------- create your scene object ---------- */
function initScene() {
  viewport.signals.overlayStarted.dispatch();
  
  const loader = new ARCH.Loader(viewport);
  
  // render a sketchup model
  loaderOption.status = 'merged';
  loaderOption.edge = true;
  loaderOption.doubleSide = false;
  loader.loadModel('/models/bugalow_fbx/model.fbx', (result) => {
    result.scale.set(0.1, 0.1, 0.1);
    ARCH.applyTransformGroup(result);
    viewport.changeLayer(viewport.layer);
    viewport.signals.overlayFinished.dispatch();
  });
  
  // test model
  // loaderOption.status = 'grouped';
  // loaderOption.edge = true;
  // loaderOption.doubleSide = false;
  // loader.loadModel('/models/test/model.dae', (result) => {
  //   console.log(result);
  //   am.refreshSelection(scene);
  // });
  
  //apartment
  // loaderOption.status = 'grouped';
  // loaderOption.edge = false;
  // loaderOption.doubleSide = false;
  // loader.loadModel('/models/kalibo/model.dae', (result) => {
  //   console.log(result);
  //   am.refreshSelection(scene);
  // })
  
  //test fbx
  // loaderOption.status = 'raw';
  // loaderOption.edge = true;
  // loaderOption.doubleSide = false;
  // loader.loadModel('/models/fbox/model.fbx', (result) => {
  //   console.log(result);
  //   am.refreshSelection(scene);
  // })
  
  //test rhino 3dm
  // loaderOption.status = 'grouped';
  // loaderOption.edge = true;
  // loaderOption.doubleSide = false;
  // loader.loadModel('/models/b_3dm/model.3dm', (result) => {
  //   viewport.changeLayer(viewport.layer);
  //   console.log(result);
  // })
}


/* ---------- main entry ---------- */
function main() {
  console.warn = () => {
  };
  viewport = new ARCH.Viewport('container/loaders-example', true, {transformer: false});
  viewport.setCameraPosition([1120, 630, 450], [160, 135, 400]);
  
  
  let env = viewport.environment;
  env.axes = false;
  env.x = 1;
  env.y = 0.5;
  env.z = 0.6;
  env.update();
  initScene();
  
}

export {
  main
}
