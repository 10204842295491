import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router';

window.DEBUG = process.env.NODE_ENV === 'development';

import ArchiWeb from '@inst-aaa/archiweb-core';

Vue.use(ArchiWeb)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
