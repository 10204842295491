import * as THREE from "three";
import * as ARCH from "@inst-aaa/archiweb-core"
import {token} from "@/sensitiveInfo"


let viewport, gui;

let lines = [];
let points, border;

let lastRandom = 1;

function random(seed) {
  seed = seed || lastRandom;
  return lastRandom = ('0.' + Math.sin(seed).toString().substr(6));
}

/* ---------- GUI setup ---------- */
const control = {
  seed: 1,
  num: 10,
  nx: 500,
  ny: 300,
}

const property = {
  d: 1,
}


function initArchiJSON() {
  let archijson = new ARCH.ArchiJSON(token);
  // set send button
  control.sendToJava = function () {
    archijson.sendArchiJSON('java-backend', [points, border], property);
  }
  
  archijson.onSetup = function () {
    control.sendToJava();
  }
  
  archijson.onReceive = function (archijson) {
    lines.forEach((line) => {
      viewport.removeObject(line);
    })
    lines = [];
    for (let e of archijson.geometryElements) {
      const line = new ARCH.Segments(viewport, e);
      lines.push(line);
    }
  }
}


function initGUI() {
  
  gui.add(control, 'seed', 0, 1).onChange(() => {
    update()
  });
  gui.add(control, 'num', 5, 1000, 1).onChange(() => {
    update()
  });
  gui.add(control, 'nx', 100, 1000, 1).onChange(() => {
    update()
  });
  gui.add(control, 'ny', 100, 1000, 1).onChange(() => {
    update()
  });
  gui.add(property, 'd', 0.5, 20).onChange(() => {
    control.sendToJava();
  });
  gui.add(control, 'sendToJava').name('Send Geometries');
}


/* ---------- create your scene object ---------- */

function initScene() {
  
  let mf = new ARCH.MaterialFactory();
  
  points = new ARCH.Vertices(viewport);
  points.material = new THREE.PointsMaterial({size: 10, vertexColors: true})
  generatePoints(control.num, control.nx, control.ny);
  
  border = new ARCH.Plane(viewport, [0, 0, 0], [control.nx, control.ny],
    {material: mf.Void(), showEdge: true});
  
}


function generatePoints(num, nx, ny) {
  let positions = [];
  let colors = [];
  random(control.seed);
  for (let i = 0; i < num; ++i) {
    const x = random() * nx - nx / 2;
    const y = random() * ny - ny / 2;
    positions.push(x, y, 0);
    colors.push(x / nx + 0.5, y / ny + 0.5, -x / nx + 0.5);
  }
  
  points.updateModel({coordinates: positions, size: 3});
  points.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
}

function update() {
  generatePoints(control.num, control.nx, control.ny);
  
  border.scale.x = control.nx;
  border.scale.y = control.ny;
  
  control.sendToJava();
}

/* ---------- main entry ---------- */
function main() {
  viewport = new ARCH.Viewport('container/java-backend-example', true, {
    selection: false,
    transformer: false,
    environment: false
  });
  gui = viewport.gui;
  
  initArchiJSON();
  initGUI();
  initScene();
}

export {
  main
}