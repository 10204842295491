"use strict";
import * as THREE from 'three'
import * as ARCH from "@inst-aaa/archiweb-core"
import * as PriorityQueue from "priorityqueuejs"
import {component} from "@inst-aaa/archiweb-core/src/components/component";

let scene;


let viewport, mt;

let balls = [];
let segs = [], bg;

let currentObj, refObj;


function randomColor() {
  return new THREE.Color().setHSL(Math.random(), 0.4, 0.7).getHex();
}

function initScene() {
  scene.background = new THREE.Color(0xfafafa);
  const light = new THREE.SpotLight(0xffffff, 0.931);
  light.position.set(0, 0, 1000);
  scene.add(light);
  
  
  mt = new ARCH.MaterialFactory();
  
  
  bg = new ARCH.Cuboid(viewport);
  bg.visible = false;
  balls = [];
  for (let i = 0; i < 30; ++i) {
    addSphere(Math.random() * 1400 - 700, Math.random() * 800 - 400)
  }
  
  viewport.changeLayer('balls')
}

// eslint-disable-next-line no-unused-vars
function addSphere(x, y) {
  let b = new ARCH.Cylinder(viewport, [x, y, 0], [Math.random() * 10 + 10, 10], {
    material: mt.Flat(randomColor()),
    showEdge: true
  });
  viewport.addObjectLayer(b, 'balls');
  b.parent = bg;
  balls.push(b);
  spanningTree(balls.map((c) => c.position));
  
}


function spanningTree(positions) {
  const pq = new PriorityQueue(function (a, b) {
    return b.dist - a.dist;
  });
  const mn = new Array(positions.length).fill(0x3f3f3f3f);
  const vis = new Array(positions.length).fill(0);
  mn[0] = 0;
  pq.enq({dist: 0, node: 0})
  const edge = []
  while (pq.size() > 0) {
    let p = pq.deq();
    let u = p.node;
    if (vis[u] === 0 && p._from !== undefined) {
      edge.push({u: p._from, v: u, dist: p.dist});
    }
    vis[u] = 1;
    for (let v = 0; v < positions.length; ++v) {
      if (v === u) continue;
      let w = positions[u].distanceTo(positions[v]);
      if (mn[v] > w) {
        mn[v] = w;
        pq.enq({dist: w, node: v, _from: u});
      }
    }
  }
  viewport.signals.sceneChanged.active = false;
  segs.forEach((e) => {
    viewport.removeObject(e);
  });
  segs = [];
  for (let e of edge) {
    segs.push(
      new ARCH.Segments(viewport, {points: [positions[e.u], positions[e.v]], closed: false})
    )
  }
  viewport.signals.sceneChanged.active = true;
}


function addMouseEvent() {
  
  viewport.dom.addEventListener('mouseup', () => {
    
    
    if (bg.dragging) return;
    refObj = currentObj;
    if (refObj) {
      component.GUICard.getLabel('color').value = '#' + refObj.material.color.getHexString();
      component.GUICard.getLabel('size').value = refObj.scale.x;
    } else {
      component.GUICard.getLabel('color').value = '#ffffff';
      component.GUICard.getLabel('size').value = 10;
    }
  })
  
  //
  viewport.dom.addEventListener('dblclick', (event) => {
    if (currentObj) {
      
      let index = balls.indexOf(currentObj);
      balls.splice(index, 1);
      spanningTree(balls.map((c) => c.position));
      
      let cmd = new ARCH.RemoveObjectCommand(viewport, currentObj);
      viewport.execute(cmd);
      scene.remove(currentObj);
      
    } else {
      let mouse = new THREE.Vector2(
        ((event.clientX - viewport.offsetLeft) / viewport.width) * 2 - 1,
        -((event.clientY - viewport.offsetTop) / viewport.height) * 2 + 1
      )
      let p = viewport.getMouseXoyPosition(mouse)
      addSphere(p.x, p.y);
      
    }
    
  });
  
  viewport.drag.addEventListener('hoveron', (event) => {
    currentObj = event.object;
  });
  
  viewport.drag.addEventListener('hoveroff', () => {
    currentObj = undefined;
  })
  
  viewport.drag.addEventListener('dragstart', () => {
    bg.dragging = true;
  })
  
  viewport.drag.addEventListener('dragend', () => {
    
    spanningTree(balls.map((c) => c.position));
    bg.dragging = false;
  })
}


function initManual() {
  component.Manual.dialog = true;
  component.Manual.title = "Interactive Spanning Tree";
  component.Manual.info = "<br> This example is a <i>Prim</i> implementation of <i>minimal spanning tree (drag version) </i>. <br> " +
    "You can directly <b>drag</b> and <b>remove</b> the node, or change the <b>size</b> and <b>color</b> of it. <br> " +
    "Add/Delete node by double click. <br>" +
    "Toggle on button <b>keeping change</b> will change the tree structure per frame. <br>"
  component.Manual.manual = [];
}


function initGUI() {
  component.GUICard.options = {
    type: 'option', label: 'Options', items: [
      {
        type: 'slider', label: 'size', value: 10, min: 10, max: 100, step: 1, onChange: (val) => {
          if (refObj) {
            refObj.scale.x = val;
            refObj.scale.y = val;
          }
        }
      },
      {
        type: 'color', label: 'color', value: '#FFFFFF', onChange: (val) => {
          if (refObj && val)
            refObj.material.color = new THREE.Color(val);
        }
      },
      {type: 'switch', label: 'changing', value: false},
    ]
  }
  component.GUICard.show = true;
}

function draw() {
  if (component.GUICard.getValue('changing') && bg.dragging) {
    spanningTree(balls.map((c) => c.position));
  }
}

function main() {
  // viewport = new ARCH.Viewport('container-3', false, {dimension: '2d', environment: false, height: 500, drag:true});
  viewport = new ARCH.Viewport('container/interactive-spanning-tree', true, {
    dimension: '2d',
    environment: false,
    drag: true
  });
  scene = viewport.scene;
  
  // viewport.controller.enabled = false;
  viewport.draw = draw;
  
  
  initManual();
  initScene();
  initGUI();
  addMouseEvent();
}

export {
  main
}
