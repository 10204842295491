/* eslint-disable no-unused-vars,no-case-declarations */
"use strict";
import * as THREE from 'three'
import * as ARCH from "@inst-aaa/archiweb-core"

let viewport;

function initScene() {
  
  const b1 = new ARCH.Cuboid(viewport, [150, 150, 0], [300, 300, 300]);
  const b2 = new ARCH.Cuboid(viewport, [-300, -300, 0], [300, 300, 100]);
  const b3 = new ARCH.Cuboid(viewport, [300, -500, 0], [300, 300, 150]);
  const b4 = new ARCH.Cylinder(viewport, [330, 430, 0], [50, 100], {showEdge: true});
  
  const coordinates = [100, 100, 200, 100, 200, 0, 300, 0, 300, 300, 100, 300];
  const segs = new ARCH.Segments(viewport, {coordinates: coordinates, size: 2, closed: true})
  segs.visible = false;
  const s1 = new ARCH.Prism(viewport, {segments: segs, height: 10, showEdge: true});
  s1.position.set(100, 200, 100);
  
  
  const loader = new ARCH.Loader(viewport);
  ARCH.loaderOption.status = "merged";
  ARCH.loaderOption.edge = true;
  
  loader.loadModel('https://model.amomorning.com/tree/spruce-tree.dae', (mesh) => {
    mesh.position.set(0, -300, 0);
    ARCH.setMaterial(mesh, new THREE.MeshLambertMaterial({color: 0x99A083, transparent: true, opacity: 0.8}))
    ARCH.setPolygonOffsetMaterial(mesh.material);
    mesh.toCamera = true;
  });
  
  loader.loadModel('https://model.amomorning.com/tree/autumn-tree.dae', (mesh) => {
    mesh.position.set(500, 0, 0);
    mesh.scale.set(2, 2, 2)
    ARCH.setPolygonOffsetMaterial(mesh.material);
    ARCH.setMaterialOpacity(mesh, 0.6);
    mesh.toCamera = true;
  });
  
  [b1, b2, b3, b4, s1].forEach(e => viewport.addObjectLayer(e, 'buildings'));
  
}


function main() {
  viewport = new ARCH.Viewport("container/3d-editor");
  initScene();
}

export {
  main
}
