import * as ARCH from "@inst-aaa/archiweb-core"

const stairpara = {
  w: 300,
  h: 50,
}
let stairs = [];


const create_stair = function (viewport) {
  
  
  for (let i = 0; i < 10; ++i) {
    stairs.push(new ARCH.Cuboid(viewport, [0, i * 50, i * 30], [300, 50, 50]));
  }
  for (let i = 0; i < 10; ++i) {
    stairs.push(new ARCH.Cuboid(viewport, [300, 450 - i * 50, i * 30 + 330], [300, 50, 50]));
  }
  
  
  new ARCH.Cuboid(viewport, [150, 650, 300], [600, 350, 50]);
  viewport.gui.add(stairpara, "w", 100, 500, 30).onChange(function () {
    stairs.forEach((ele) => {
      ele.scale.x = stairpara.w;
    })
  });
  viewport.gui.add(stairpara, "h", 10, 100, 5).onChange(function () {
    stairs.forEach((ele) => {
      ele.scale.z = stairpara.h;
    })
  });
}

export {
  create_stair
}