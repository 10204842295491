import Vue from 'vue'
import VueRouter from 'vue-router'
import Viewport from './components/Viewport'
import Home from './components/Home'
import Map from './components/Map'
import Login from './components/Login'
import Workspace from "@/components/Workspace";
import ViewBoard from "@/components/ViewBoard";
//
// const examples = [
//   // test examples
//   'polygon-handles', 'csg',
//   // website examples
//   '2d-editor', '3d-editor', 'loaders-example',
//   'marching-cubes', 'material-example', 'shape-2d',
//   'undo-redo-canvas', 'image-3d', 'java-backend-example',
//   'archijson-geometry', 'interactive-spanning-tree',
//   'cascaded-shadow-maps', 'python-backend-example', 'mapbox-buildings',
//   'logo'
//
// ]

const examples = [
  {name: '3d-editor', author: 'amomorning', app: true, gui: false},
  {name: '2d-editor', author: 'amomorning', app: false, gui: true},
  {name: 'interactive-spanning-tree', author: 'amomorning', app: false, gui: false},
  {name: 'java-backend-example', author: 'amomorning', app: true, gui: true},
  // {name: 'archijson-geometry', author: 'amomorning', app: true, gui: false},
  {name: 'image-3d', author: 'amomorning', app: true, gui: true},
  {name: 'marching-cubes', author: 'amomorning', app: true, gui: true},
  {name: 'shape-2d', author: 'amomorning', app: true, gui: true},
  {name: 'python-backend-example', author: 'amomorning', app: true, gui: false},
  {name: 'loaders-example', author: 'amomorning', app: true, gui: false},
  {name: 'polygon-handles', author: 'amomorning', app: true, gui: false},
  {name: 'mapbox-buildings', author: 'amomorning', app: false, gui: true},
  {name: 'archijson-geometry', author: 'amomorning', app: true, gui: true},
  {name: 'dxf-loader', author: 'amomorning', app: false, gui: false},
    {name: 'additive-wave', author: 'amomorning', app: false, gui: false},
  {name: 'layer-import', author: 'amomorning', app: true, gui: false},
]
const routes = [];
examples.forEach((e) => {
  // let item = e.name;
  let res = e.name.split('-');
  for (let i = 0; i < res.length; ++i) {
    res[i] = res[i].replace(/^\S/, s => s.toUpperCase());
  }
  let title = res.join(' ');
  let compo = (e.name.indexOf('mapbox') !== -1) ? Map : Viewport;
  routes.push({
    path: '/' + e.name,
    name: title,
    component: compo,
    meta: {title: 'ArchiWeb ' + title, author: e.author, app: e.app, gui: e.gui}
  })
})

routes.push(
  {path: '/login', name: 'login', component: Login, meta: {title: 'Login'}},
  {path: '/workspace', name: 'workspace', component: Workspace, meta: {title: 'ArchiWeb Workspace'}},
  {
    path: '/viewboard/:token',
    name: 'viewboard',
    component: ViewBoard,
    meta: {title: 'ArchiWeb ViewBoard'},
    props: true
  },
  // {path: '/', name: 'map', component: Map, meta: {title: 'Map'}},
  {path: '/', name: 'home', component: Home, meta: {title: 'ArchiWeb'}}
)

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || 'ArchiWeb';
  });
});
export {
  router as default,
  examples
}
