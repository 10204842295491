<template>
  <v-app>
  
    <TopNav v-if="!this.$route.meta.app"></TopNav>
  
    <Viewer v-if="!this.$route.meta.app" :container="'container'+this.$route.path"
            :filename="'examples' + this.$route.path + '.js'"
            :gui="this.$route.meta.gui"></Viewer>
  
    <v-app v-else>
    
      <SideDrawer v-model="drawer" :githubID="authors" :items="items"></SideDrawer>
    
      <v-app-bar absolute app color="transparent" flat>
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
      
      
        <a class="text-decoration-none" href="/#/" style="color:#000000;">
          <h2 class="pr-2">{{ title }}</h2>
        </a>
      </v-app-bar>
      <v-main class="pt-0">
        <Viewer :container="'container'+this.$route.path" :filename="'examples' + this.$route.path + '.js'"
                :gui="this.$route.meta.gui"></Viewer>
        <Toolbox v-if="!$vuetify.breakpoint.mobile" id="toolbox"></Toolbox>
        <Footer></Footer>
      </v-main>
  
    </v-app>
  </v-app>
</template>

<script>

import Footer from "@/components/Footer";
import TopNav from "@/components/TopNav";

export default {
  name: 'Viewport',
  components: {TopNav, Footer},
  data: () => ({
    title: '',
    drawer: null,
    authors: [],
    items: []
  }),
  mounted() {
    console.log(this.title)
    this.title = this.$route.name;
    this.authors.push(this.$route.meta.author);
    // this.filename='examples' + this.$route.path + '.js';
  }
};
</script>
