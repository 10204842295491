import * as ARCH from "@inst-aaa/archiweb-core"
import {component} from "@inst-aaa/archiweb-core/src/components/component";
import {getSegmentsArea} from "@inst-aaa/archiweb-core";
import {MeshPhongMaterial} from "three"


let viewport;

const farmcolors =[0xc7c053, 0x2b371b, 0x8e9f45, 0x667532, 0xa4a948, 0xb0bd51, 0x404223, 0x455525, 0x7f8a3b];
const greencolors = [0x46994E,0x98F347, 0x5C8C4C,0x819631]
function initGUI() {
    component.GUICard.options = {
        type: 'option', label: 'File Import', items: [

            {
                type: 'button', label: 'import', value: () => {
                    viewport.loader.fileInput.click();
                }
            },

            {
                type: 'switch', label: '3D', value: true, onChange: (val) => {
                    if (val) {
                        viewport.showLayerObject('3d');
                    } else {
                        viewport.hideLayerObject('3d');
                    }
                }
            },


        ]
    }


    component.GUICard.show = true;
}


function initScene() {
    viewport.loader.loadModel('/models/dxf/dongpo_small.dxf', (result) => {
        result.children.forEach((r) => {
            r.properties.layer = r.layer
            if (r.layer[1] === 'building' && r.closed) {
                let height = Math.floor(Math.random() * 10 / 3) * 3 + 3;
                console.log()
                if (getSegmentsArea(r) > 300) height = Math.floor(Math.random() * 7 / 3) * 3 + 12;

                let tmp = new ARCH.Prism(viewport, {segments: r, height: height});
                viewport.addObjectLayer(tmp, '3d');
            } else if (r.layer[1] === 'farmland' && r.closed) {
                let farmcolor = farmcolors[Math.floor(Math.random() * farmcolors.length)]
                let tmp = new ARCH.Prism(viewport, {
                    segments: r,
                    height: 0.4,
                    material: new MeshPhongMaterial({color: farmcolor})
                })
                viewport.addObjectLayer(tmp, '3d');
            } else if (r.layer[1] === 'green' && r.closed) {
                let greencolor = greencolors[Math.floor(Math.random() * greencolors.length)]
                let tmp = new ARCH.Prism(viewport, {
                    segments: r,
                    height: 0.4,
                    material: new MeshPhongMaterial({color: greencolor})
                })
                viewport.addObjectLayer(tmp, '3d');
            } else if (r.layer[1] === 'water' && r.closed) {
                let tmp = new ARCH.Prism(viewport, {
                    segments: r,
                    height: 0.2,
                    material: new MeshPhongMaterial({color: 0x4271AD})
                })
                viewport.addObjectLayer(tmp, '3d');
            } else if (r.layer[1] === 'road' && r.closed) {
                let tmp = new ARCH.Prism(viewport, {
                    segments: r,
                    height: 0.5,
                    material: new MeshPhongMaterial({color: 0x222222})
                })
                viewport.addObjectLayer(tmp, '3d');

            }
        })
    })
}
function main() {
    viewport = new ARCH.Viewport("container/dxf-loader");
    initScene();
    initGUI();
}

export {
    main
}
