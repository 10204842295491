"use strict";
import * as THREE from 'three'
import * as ARCH from "@inst-aaa/archiweb-core"
import {component} from "@inst-aaa/archiweb-core/src/components/component";
import {token} from "@/sensitiveInfo";


let viewport;

let vs, fs, mesh, rawmesh;

const properties = {
  edge: 10,
  alpha: 95,
  zmax: 450.0
}


function initArchiJSON() {
  let archijson = new ARCH.ArchiJSON(token);
  
  let send = function () {
    archijson.sendArchiJSON('python-backend', [rawmesh], properties);
  }
  
  
  component.GUICard.options = {
    type: 'option', label: 'Parameters', items: [
      {
        type: 'slider', label: 'edge', value: properties.edge, min: 5, max: 20, step: 0.1, onChange: (val) => {
          properties.edge = val;
        }
      },
      {
        type: 'slider', label: 'alpha', value: properties.alpha, min: 80, max: 120, step: 0.1, onChange: (val) => {
          properties.alpha = val;
        }
      },
      {
        type: 'slider', label: 'zmax', value: properties.zmax, min: 300, max: 1000, step: 1, onChange: (val) => {
          properties.zmax = val;
        }, thumbs: true
      },
      {
        type: 'button', label: 'update', value: () => {
          send();
        }
      },
    ]
  }
  component.GUICard.show = true;
  let mt = new ARCH.MaterialFactory();
  archijson.onReceive = function (obj) {
    console.log(obj)
    if (mesh) viewport.removeObject(mesh);
    obj.faces.count[1] = 0;
    mesh = new ARCH.Mesh(viewport, {vertices: obj.vertices, faces: obj.faces, material: mt.Doubled()});
    viewport.changeLayer(viewport.layer);
  }
  archijson.onSetup = function () {
    send();
  }
}


function initScene() {
  
  
  let pts = []
  for (let i = 0; i < 11; ++i) {
    for (let j = 0; j < 11; ++j) {
      pts.push(new THREE.Vector3(i * 100, j * 100, 0));
    }
  }
  
  
  vs = new ARCH.Vertices(viewport, {points: pts});
  fs = []
  for (let i = 0; i < 10; ++i) {
    for (let j = 0; j < 10; ++j) {
      let face = [i * 11 + j, i * 11 + j + 1, (i + 1) * 11 + j + 1, (i + 1) * 11 + j];
      new ARCH.Segments(viewport, {points: face.map(id => pts[id]), closed: true})
      fs.push(...face)
    }
  }
  rawmesh = new ARCH.Mesh(viewport, {vertices: vs.toJSON(), faces: {index: fs, count: [fs.length / 4], size: [4]}})
  rawmesh.visible = false;
  
  
}

function main() {
  viewport = new ARCH.Viewport("container/python-backend-example");
  
  viewport.setCameraPosition([1400, -800, 300], [600, 250, 200]);
  
  
  initArchiJSON();
  initScene();
}

export {
  main
}
