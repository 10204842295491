<template>
  <v-app>
    <TopNav></TopNav>
    <div
      id="gui-container"
      style="position:absolute;right:0;z-index:999"
    ></div>
    <div id="map" style="bottom:2.1em"></div>
    <div id="hidden-container"></div>
    <Notification></Notification>
    <LoaderOption></LoaderOption>
    <Manual></Manual>
    
    <GUICard style="position: absolute; top: 50px; right: 50px; z-index: 1"></GUICard>
    <InfoCard style="position: absolute; top: 50px; left: 20px; z-index: 1"></InfoCard>
    <Overlay></Overlay>
    <Footer></Footer>
  
  </v-app>

</template>

<script>
import TopNav from "@/components/TopNav";
import Footer from "@/components/Footer";

export default {
  name: "Map",
  components: {Footer, TopNav},
  data: () => ({}),
  mounted() {
    
    const examples = require('@/examples' + this.$route.path + '.js');
    examples.main();
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>