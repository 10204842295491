<template>

    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  
}
</script>

<style>
*::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ababab;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}
</style>