import * as ARCH from "@inst-aaa/archiweb-core"
import * as THREE from 'three'

let DRAWMODE = false;

let viewport, gui;
let box, buffer, polyline, path;


function getRandomInt(num) {
  return Math.floor(Math.random() * Math.floor(num));
}

const controls = new function () {
  const lines = [];
  const shapes = [];
  
  this.height = 100;
  this.draw = function () {
    DRAWMODE = !DRAWMODE;
    viewport.dom.style.cursor = DRAWMODE ? 'pointer' : 'auto';
    if (DRAWMODE) buffer = [];
  }
  this.shape = function () {
    let segs = new ARCH.Segments(viewport, {points: path.getPoints()});
    lines.push(segs);
    shapes.push(new ARCH.Prism(viewport, {segments: segs, height: controls.height || getRandomInt(20)}))
    buffer = [];
    viewport.changeLayer(viewport.layer);
  }
  this.clear = function () {
    lines.forEach((item) => {
      viewport.scene.remove(item);
    })
    polyline.geometry.setFromPoints([new THREE.Vector3()]);
    buffer = [];
  }
  this.grid = false;
};


function onClick(event) {
  if (DRAWMODE) {
    let mouse = new THREE.Vector2(
      ((event.clientX - viewport.offsetLeft) / viewport.width) * 2 - 1,
      -((event.clientY - viewport.offsetTop) / viewport.height) * 2 + 1
    )
    let pt = viewport.getMouseXoyPosition(mouse);
    
    if (controls.grid) {
      
      pt.x = Math.round(pt.x / 10) * 10;
      pt.y = Math.round(pt.y / 10) * 10;
    }
    box.position.copy(pt);
    buffer.push(pt);
    
    path = new THREE.Path();
    path.moveTo(buffer[0].x, buffer[0].y)
    for (let i = 1; i < buffer.length; ++i) {
      path.lineTo(buffer[i].x, buffer[i].y);
    }
    path.lineTo(buffer[0].x, buffer[0].y);
    
    polyline.geometry.setFromPoints(path.getPoints());
    
  }
}

function initScene() {
  viewport.scene.background = new THREE.Color('0xffffff');
  
  box = new ARCH.Cuboid(viewport, [2, 2, 0], [2, 2, 2]);
  polyline = new ARCH.Segments(viewport, {lineMaterial: new THREE.LineBasicMaterial({color: 0xff0000})});
  
  /* ---------- light ---------- */
  const light = new THREE.SpotLight(0xffffff, 1.5);
  light.position.set(0, 0, 1000);
  viewport.scene.add(light);
  
  const light2 = new THREE.SpotLight(0xffffff, 1);
  light2.position.set(1000, -1000, 1000);
  viewport.scene.add(light2);
  
  viewport.scene.add(grid);
  grid.rotation.x = Math.PI / 2.0;
  let grid1 = new THREE.GridHelper(1000, 100, 0x888888);
  grid1.material.color.setHex(0x888888);
  grid1.material.vertexColors = false;
  grid.add(grid1);
  
  let grid2 = new THREE.GridHelper(1000, 10, 0x222222);
  grid2.material.color.setHex(0x222222);
  grid2.material.depthFunc = THREE.AlwaysDepth;
  grid2.material.vertexColors = false;
  grid.add(grid2);
  
  grid.visible = controls.grid;
  
}

let grid = new THREE.Group();

function initGUI() {
  gui.add(controls, 'height');
  gui.add(controls, 'draw');
  gui.add(controls, 'shape');
  gui.add(controls, 'clear');
  
  
  gui.add(controls, 'grid').onChange(() => {
    grid.visible = controls.grid;
  });
  
}

function main() {
  viewport = new ARCH.Viewport("container/shape-2d", true, {environment: false, transformer: false});
  gui = viewport.gui;
  
  viewport.dom.addEventListener('click', onClick, false);
  
  initScene();
  initGUI();
}

export {
  main
}