/* eslint-disable no-unused-vars,no-case-declarations */
let accesstoken = 'pk.eyJ1IjoiYW1vbW9ybmluZyIsImEiOiJja24wZHhsc2swbjBwMnBtbjh5NXcwdDhvIn0.ua6Cnnx7aTl6jGhGm4FzrQ';
let token = '6a8c6af8-2491-40e1-9cf6-dbcdba86384d'
// let token = 'c087f625-16dd-4185-8993-5f03115ea37b'
// let token = 'e2f555ad-5571-4de3-8be5-828c84c62e62'; // grasshopper archijson test

// let uri = process.env.NODE_ENV === 'development' ? "http://localhost:27781": "https://web.archialgo.com"
let uri = "https://web.archialgo.com"
const urls = {
  login: uri + '/api/user/login',
  insertUser: uri + '/api/user/insert',
  deleteToken: uri + '/api/token/delete',
  findUser: uri + '/api/user/find',
  createToken: uri + '/api/token/create',
  tokenConn: uri + '/api/connection/token',
  userConn: uri + '/api/connection/user',
  updatePassword: uri + '/api/user/update'
}

export {
  accesstoken,
  uri,
  urls,
  token,
}
