import * as ARCH from "@inst-aaa/archiweb-core"
import {building} from "@/assets/models/csg";
import {token} from "@/sensitiveInfo"

let viewport;

let reconstructed = [];
let balls = []
let segments, prism, vertices, mesh, circle, sphere;

/* ---------- GUI setup ---------- */
const control = {
  
  dragChanging: false
}

function initGUI() {
  viewport.gui.add(control, 'send');
  viewport.gui.add(control, 'dragChanging');
}

function initArchiJSON() {
  let archijson = new ARCH.ArchiJSON(token);
  
  archijson.onReceive = function (o) {
    console.log(o);
    for (let e of o.geometryElements) {
      
      let b = viewport.objectByUuid(e.uuid);
      console.log(b);
      
      if (!b) {
        if (e.type === 'Mesh') {
          b = new ARCH.Mesh(viewport, {vertices: e.vertices, faces: e.faces});
          b.position.set(e.position.x, e.position.y, e.position.z)
          
        } else {
          b = new ARCH[e.type](viewport);
          console.log(b);
          b.position.set(e.position.x, e.position.y, e.position.z)
        }
        reconstructed.push(b)
      }
      
      let uuid = b.uuid;
      b.fromJSON(e);
      b.uuid = uuid;
    }
    
    viewport.changeLayer(viewport.layer);
  }
  
  /* set send button */
  control.send = function () {
    reconstructed.forEach((it) => {
      it.parent.remove(it);
    })
    reconstructed = [];
    
    archijson.sendArchiJSON('archijson', exchanged);
  }
}

let exchanged = [];

/* ---------- create your scene object ---------- */
function initScene() {
  let mt = new ARCH.MaterialFactory();
  
  const cuboid = new ARCH.Cuboid(viewport, [100, 100, 0], [200, 200, 300], {material: mt.Matte(0xff0000)});
  
  const cylinder = new ARCH.Cylinder(viewport, [400, 300, 0], [100, 400], {
    material: mt.Matte(0xffff00),
    showEdge: true
  });
  
  const plane = new ARCH.Plane(viewport, [-600, 300, 5], [600, 600], {material: mt.Matte(0xff00ff), showEdge: true})
  
  // let points = [[-190, 730, 6], [320, 940, 6], [520, 640, 6], [240, 410, 6], [50, 500, 6], [-110, 460, 6]]
  let points = [
    [-110, 460, 6],
    [50, 500, 6],
    [240, 410, 6],
    [520, 640, 6],
    [320, 940, 6],
    [-190, 730, 6]
  ]
  points.forEach((p) => balls.push(new ARCH.Sphere(viewport, p, 10, {material: mt.Flat(0xff0000)})));
  
  segments = new ARCH.Segments(viewport, {points: balls.map((handle) => handle.position), closed: true});
  
  prism = new ARCH.Prism(viewport, {
    segments: segments,
    material: mt.Matte(0x0000ff), height: 1
  })
  prism.position.z = 5;
  
  /* ---------- generate points each 5000 area ---------- */
  vertices = new ARCH.Vertices(viewport, {points: ARCH.pointsInsideSegments(segments, 5000), pointSize: 6})
  
  
  /* ---------- generate mesh ---------- */
  mesh = new ARCH.Mesh(viewport, {
    vertices: {coordinates: building.verts.flat(), size: 3},
    faces: {index: building.faces.flat()},
    material: mt.Flat()
  });

  circle = new ARCH.Circle(viewport, [1500, 300, 0], [100, Math.PI*2/3])

  sphere = new ARCH.Sphere(viewport, [2000, 300, 0], 200);

  exchanged = [cuboid, cylinder, plane, segments, vertices, mesh, circle, sphere];
  
  viewport.changeLayer(viewport.layer);
}


function draw() {
  if (control.dragChanging && curO) {
    segments.setFromPoints((balls.map((handle) => handle.position)))
    prism.setFromSegments(segments);
    
    vertices.updateModel({points: ARCH.pointsInsideSegments(segments, 5000)})
    // vertices.coordinates = Array.from(vertices.geometry.getAttribute('position').array);
    // console.log(vertices.toJson());
    
  }
}

let curO = undefined;

function draggingChanged(o, event) {
  if (event && balls.includes(o)) {
    curO = o;
  } else {
    curO = undefined;
  }
  
  if (!event && balls.includes(o)) {
    
    segments.setFromPoints((balls.map((handle) => handle.position)))
    prism.setFromSegments(segments);
    
    vertices.updateModel({points: ARCH.pointsInsideSegments(segments, 5000)})
    // vertices.geometry.setFromPoints(ARCH.pointsInsideSegments(segments, 5000))
  }
}


/* ---------- main entry ---------- */
function main() {
  viewport = new ARCH.Viewport("container/archijson-geometry");
  
  viewport.transformer.draggingChanged = draggingChanged;
  viewport.draw = draw;
  initArchiJSON();
  initGUI();
  initScene();
  
}

export {
  main
}
