<template>
  <div>
    <v-app-bar
      app
      color="transparent"
      flat
      style="background: linear-gradient(rgba(100, 100, 100, 0.5), rgba(0, 0, 0, 0)); z-index: 99"
    >
      <v-row class="d-flex align-end mx-3 text-subtitle-1">
  
        <a class="text-decoration-none" href="/#/" style="color:#000000;">
          <v-img
            alt="Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo-black.png"
            transition="scale-transition"
            width="30"
          />
        </a>
        <a class="text-decoration-none" href="/#/" style="color:#000000;">
          <h2 class="pr-2">ArchiWeb</h2>
        </a>
  
        <v-spacer></v-spacer>
  
        <v-btn v-if="checkURL(this.$route.path)"
               @click="scrollToPosition('features')"
               class="hidden-md-and-down font-weight-bold"
               large plain rounded>
          features
        </v-btn>
  
        <v-btn v-if="checkURL(this.$route.path)"
               large plain rounded
               class="font-weight-bold hidden-sm-and-down"
               href="https://web.archialgo.com/works/"
               target="_blank">
          works
        </v-btn>
  
        <v-btn v-if="checkURL(this.$route.path)"
               class="hidden-md-and-down font-weight-bold"
               @click="scrollToPosition('playground')"
               large plain rounded>
          playground
        </v-btn>
  
        <v-btn v-if="checkURL(this.$route.path)"
               class="hidden-sm-and-down font-weight-bold"
               @click="scrollToPosition('examples')"
               large plain rounded>
          Gallery
        </v-btn>
  
        <v-btn v-if="checkURL(this.$route.path)"
               class="hidden-sm-and-down font-weight-bold"
               large plain rounded to="/workspace">
          workspace
        </v-btn>
  
        <v-btn v-if="checkURL(this.$route.path)"
               class="hidden-md-and-up"
               icon to="/workspace">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </v-row>
    
    
    </v-app-bar>
  </div>
</template>

<script>
export default {
  methods: {
    checkURL(url) {
      // console.log(url)
      if (url.indexOf('viewboard') >= 0 || url === '/')
        return true;
      return false;
    },
    scrollToPosition(key) {
      document.querySelector('#' + key).scrollIntoView();
    }
  }
}

</script>
