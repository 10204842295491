import * as ARCH from "@inst-aaa/archiweb-core";
import {component} from "@inst-aaa/archiweb-core/src/components/component";
import {AddObjectCommand} from "@inst-aaa/archiweb-core/src/commands/AddObjectCommand";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";

let viewport;

function initGUI() {
    let form = document.createElement('form');
    form.style.display = 'none';
    document.body.appendChild(form);

    let fileInput = document.createElement('input');
    fileInput.multiple = true;
    fileInput.type = 'file';
    fileInput.addEventListener('change', function () {

        let reader = new FileReader()
        reader.addEventListener('load', function (event) {

            let content = event.target.result;
            let loader = new FBXLoader();

            for(let layer of loader.parse(content).children) {
                if(!layer.userData.originalName || !layer.isGroup) continue;
                console.log(layer)

                const layername = layer.userData.originalName

                for(let mesh of layer.children) {
                    // viewport.addObjectLayer(o, layer.)
                    if(!mesh.isMesh) continue;
                    let color = 0xff0000;
                    if(layername === 'green') color = 0x00ff00;
                    if(layername === 'blue') color = 0x0000ff;

                    mesh.add(ARCH.createMeshWireframe(mesh, color, 0.005))
                    mesh.add(ARCH.createMeshEdge(mesh))
                    mesh.children[0].visible = false

                    mesh.layer = ['default']
                    let cmd = new AddObjectCommand(viewport, mesh);
                    viewport.execute(cmd)
                    viewport.addObjectLayer(mesh, layername)

                }
            }
        })
        reader.readAsArrayBuffer(fileInput.files[0])
        form.reset();
    });
    form.appendChild(fileInput)

    component.GUICard.options = {
        type: 'option', label: 'Options', items: [
            {
                type: 'button', label: 'import', value: ()=>{
                    fileInput.click()
                }
            }]
    }
    component.GUICard.show = true;
}

function main() {
    viewport = new ARCH.Viewport("container/layer-import", true);

    initGUI();
}

export {
    main
}