import * as THREE from "three";
import * as ARCH from "@inst-aaa/archiweb-core"
import {component} from "@inst-aaa/archiweb-core/src/components/component";

let viewport, scene, mt;
let currentObj;
const balls = []
const sources = []

const scale = 15;
const N = 80;
const M = 50;

function initGUI() {
    component.GUICard.options = {
        type: 'option', label: 'Options', items: [
            {
                type: 'selects', label: 'radius',
                value: 'random', items: ['random', '20', '30', '40'],
                onChange: (val) => {
                    for (let s of sources) {
                        s.updateModel({radius: Number(val) ? Number(val) : Math.random() * 20 + 20});
                    }
                }
            },
            {
                type: 'slider', label: 'num',
                value: 2, min: 0, max: 7, step: 1,
                onChange: (val) => {
                    while (sources.length > val) {
                        const u = sources[sources.length - 1]
                        sources.splice(sources.length - 1, 1);
                        viewport.execute(new ARCH.RemoveObjectCommand(viewport, u))
                        scene.remove(u)
                    }
                    while (sources.length < val) {
                        createSource(Math.random() * N - N / 2, Math.random() * M - M / 2);
                    }
                }
            },
            {type: 'switch', label: 'waving', value: false},
            {type: 'switch', label: 'moving', value: true},
        ]
    }
    component.GUICard.show = true;
}

function createSource(x, y) {
    const val = component.GUICard.getValue('radius')

    const c = new ARCH.Cylinder(viewport,
        [x, y, 0],
        [Number(val) ? Number(val) : Math.random() * 20 + 20, 1],
        {showEdge: true}
    )
    c.v = new THREE.Vector2()
    sources.push(c)
    viewport.addObjectLayer(c, 'sources');
    viewport.changeLayer('sources');
}

function initScene() {
    scene.background = new THREE.Color(0xfafafa);
    const light = new THREE.SpotLight(0xffffff, 0.931);
    light.position.set(0, 0, 1000);
    scene.add(light);

    mt = new ARCH.MaterialFactory();

    viewport.signals.sceneChanged.active = false;
    for (let i = 0; i < N; ++i) {
        for (let j = 0; j < M; ++j) {
            let b = new ARCH.Cylinder(viewport,
                [i * scale - scale * N / 2, j * scale - scale * M / 2, 0],
                [scale / 2 - 1, 10],
                {material: mt.Flat(0x333333), showEdge: true});
            balls.push(b)
        }
    }

    viewport.signals.sceneChanged.active = true;
    createSource(Math.random() * N - N / 2, Math.random() * M - M / 2)
    createSource(Math.random() * N - N / 2, Math.random() * M - M / 2);
}


function addMouseEvent() {

    viewport.drag.addEventListener('hoveron', (event) => {
        if (event.object.layer.includes('sources')) {
            currentObj = event.object;
        }
    });

    viewport.drag.addEventListener('hoveroff', () => {
        currentObj = undefined;
    })

    viewport.dom.addEventListener('dblclick', (event) => {
        if (currentObj && currentObj.layer.includes('sources')) {
            const index = sources.indexOf(currentObj);
            sources.splice(index, 1);

            viewport.execute(new ARCH.RemoveObjectCommand(viewport, currentObj))
            scene.remove(currentObj)

            for (let it of component.GUICard.options.items)
                if (it.label === 'num') it.value -= 1
        } else {
            let mouse = new THREE.Vector2(
                ((event.clientX - viewport.offsetLeft) / viewport.width) * 2 - 1,
                -((event.clientY - viewport.offsetTop) / viewport.height) * 2 + 1
            )
            let p = viewport.getMouseXoyPosition(mouse);
            createSource(p.x, p.y)

            for (let it of component.GUICard.options.items)
                if (it.label === 'num') it.value += 1
        }
    })
}


let t = 0

function draw() {

    if (component.GUICard.getValue('waving')) t += Math.PI / 60;

    if (component.GUICard.getValue('moving')) {
        for (let s of sources) {
            if (s.position.x > scale * N / 2) {
                s.v.x -= Math.random()
            } else if (s.position.x < -scale * N / 2) {
                s.v.x += Math.random()
            } else {
                s.v.x += Math.random() * 0.5 - 0.25
            }
            if (s.position.y > scale * M / 2) {
                s.v.y -= Math.random()
            } else if (s.position.y < -scale * M / 2) {
                s.v.y += Math.random()
            } else {
                s.v.y += Math.random() * 0.5 - 0.25
            }
            s.v.normalize()

            s.position.x += s.v.x
            s.position.y += s.v.y
        }
    }

    for (let b of balls) {
        let l = 0;
        for (let s of sources) {
            l += Math.cos(b.position.distanceTo(s.position) / s.scale.x * Math.PI / 2 - t)
        }
        l /= sources.length
        b.updateModel({radius: sources.length ? l * (scale / 2 - 1) : scale / 2 - 1})
    }
}

function main() {
    viewport = new ARCH.Viewport('container/additive-wave', true, {
        dimension: '2d',
        environment: false,
        drag: true
    });
    scene = viewport.scene;

    viewport.draw = draw;
    initScene();
    initGUI();
    addMouseEvent();
}

export {
    main
}
