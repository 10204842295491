<template>
  <v-app>
  
    <TopNav></TopNav>
  
    <section>
      <v-card class="my-10 pb-10 align-center justify-center" color="transparent"
              flat
              style="position:absolute; top:500px; left:50%; transform: translate(-50%,-50%);z-index: 10">
        <v-card-title class="justify-center display-1 text-sm-h2 text-md-h1" style="text-shadow: 2px 2px #ffffffaa">
          <a>
            <v-img
              alt="Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logo-black.png"
              transition="scale-transition"
              :width="logoWidth"
            />
          </a>
          ArchiWeb
        </v-card-title>
        <v-card-text class="justify-center text-center text-subtitle-1 text-md-h5"
                     style="color:#333333; text-shadow: 1px 1px #FFFFFFaa">
          Web-based Open Source Framework and Editor for Computational Design
        </v-card-text>
      
        <v-card-actions class="justify-center">
          <v-btn href="https://github.com/Inst-AAA/archiweb/" target="_blank">
            <v-icon>mdi-github</v-icon>
            star
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                dark
                href="http://docs.web.archialgo.com/#/zh-cn/basic/Install"
                v-bind="attrs"
                v-on="on"
              >
                Install
              </v-btn>
            </template>
            <span> Available with SEU-WLAN </span>
          </v-tooltip>
      
        </v-card-actions>
      </v-card>
    
      <v-carousel
        class="mb-15"
        cycle
        height="1000"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          :to="item.to"
        >
      
        </v-carousel-item>
      </v-carousel>
    
      <v-card color="transparent" id="features" width="100" height="50" flat></v-card>
    
      <v-container>
        <h1 class="text-center display-1 hidden-md-and-up py-5"> Web-based Interactive Tools </h1>
      </v-container>
    
      <v-card class="pb-15 mb-10 mx-10 mx-md-auto" color="transparent" flat max-width="1100">
        <v-card-text class="text-center subtitle-1 hidden-md-and-up py-5">
          ArchiWeb is an open source web-based 3D editor, which can assist architects to exhibit computational
          design.
        </v-card-text>
        <v-row>
          <v-col class="col-12 col-md-5">
            <v-card-title class="text-h3 hidden-sm-and-down">Web-based <br> Interactive Tools</v-card-title>
            <v-card-text class="subtitle-1 hidden-sm-and-down">
              ArchiWeb is an open source web-based 3D editor, which can assist architects to exhibit computational
              design.
            </v-card-text>
            <v-card-actions class="justify-center justify-md-start">
              <v-btn class="mx-2" color="secondary" outlined @click="scrollToPosition('examples')">Examples</v-btn>
            
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    color="primary"
                    dark
                    href="http://docs.web.archialgo.com/#/zh-cn/"
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                  >
                    Tutorial
                  </v-btn>
                </template>
                <span> Available with SEU-WLAN </span>
              </v-tooltip>
            
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    color="grey--darken-3"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    href="http://video.web.archialgo.com"
                    target="_blank"
                  >
                    Lectures
                  </v-btn>
                </template>
                <span>Available with SEU-WLAN</span>
              </v-tooltip>
          
          
            </v-card-actions>
          </v-col>
          <v-col class="col-12 col-md-7">
            <v-img src="/images/interactive.png"></v-img>
          </v-col>
        </v-row>
      </v-card>
    
      <v-container>
        <h1 class="text-center display-1 hidden-md-and-up py-5"> Networking of Architectural Algorithms </h1>
      </v-container>
    
      <v-card class="pb-15 mb-10 mx-10 mx-md-auto" color="transparent" flat max-width="1100">
        <v-card-text class="text-center subtitle-1 hidden-md-and-up py-5">
        
          With ArchiWeb you can create distributed, multi-language application where components communicate
          using ArchiJSON
        </v-card-text>
        <v-row>
          <v-col class="col-12 col-md-7">
            <v-img src="/images/archiweb.png"></v-img>
          </v-col>
          <v-col class="col-12 col-md-5 hidden-sm-and-down">
            <v-card-title class="text-h3">Networking of <br>Architectural <br>Algorithms</v-card-title>
            <v-card-text class="subtitle-1">
              <li>
                With ArchiWeb you can create distributed, multi-language application where components communicate
                using ArchiJSON
              </li>
              <li>
                Deploying architectural algorithms atomically based on tokens, breaking down the architectural design
                problem into a set of well-defined problems that can be implemented by invoking algorithms from
                different end points
              </li>
              <li>
                Keeping security in mind from protocol to software design
              </li>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    
    
      <v-container>
        <h1 class="text-center display-1 hidden-md-and-up py-5"> Networking of Apps and Devices </h1>
      </v-container>
    
      <v-card class="pb-15 mb-10 mx-10 mx-md-auto" color="transparent" flat max-width="1100">
        <v-card-text class="text-center subtitle-1 hidden-md-and-up py-5">
          ArchiWeb can be mounted on connected devices such as sensors and act as an operations and maintenance
          platform for the physical world throughout the lifecycle of the building.
      
        </v-card-text>
        <v-row>
          <v-col class="col-12 col-md-5 hidden-sm-and-down">
            <v-card-title class="text-h3">Networking <br>of Apps <br>and Devices</v-card-title>
            <v-card-text class="subtitle-1">
              ArchiWeb can be mounted on connected devices such as sensors and act as an operations and maintenance
              platform for the physical world throughout the lifecycle of the building.
            
              <li>
              
                Protecting the intellectual property rights of design creations
              </li>
              <li>
                Developing the company's design database
            
              </li>
            </v-card-text>
          </v-col>
          <v-col class="col-12 col-md-7">
            <v-img src="/images/applications.png"></v-img>
          </v-col>
        </v-row>
      </v-card>
    
    
      <v-container id="playground">
        <h1 class="text-center display-2 hidden-xs-only pt-10"> Online Coding Playground </h1>
        <h1 class="text-center display-1 hidden-sm-and-up py-5"> Online Coding Playground </h1>
      </v-container>
    
      <v-card class="py-10 mb-10 mx-10 mx-md-auto" color="transparent" flat max-width="900">
        <v-card-text class="text-center subtitle-1">
          Without installing or configuring, Playground eliminates the need for complex development tools.
          <br>
          Users can write code using the Web Code Editor and view renderings instantly.
      
        </v-card-text>
        <v-img src="/images/playground.png"></v-img>
        <v-card-actions class="align-center text-center justify-center">
          <v-btn color="primary" href="https://web.archialgo.com/playground">
            GO
          </v-btn>
          <v-btn href="http://docs.web.archialgo.com/#/zh-cn/guide/Playground">
            How to use?
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-container id="examples">
        <h1 class="text-center display-2 hidden-xs-only pt-10"> Gallery </h1>
        <h1 class="text-center display-1 hidden-sm-and-up py-5"> Gallery </h1>
      </v-container>
      <v-card class="py-10 mb-10 mx-md-auto" color="transparent" flat max-width="1100">
    
    
        <v-row class="py-10 mb-10 mx-5">
          <v-col
            v-for="(theme,id) in themes"
            :key="id"
            class="elevation-0 col-12 col-sm-6 col-md-4"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                :class="{ 'on-hover': hover }"
                :elevation="hover ? 10 : 4"
                :to="theme.name"
                class="mx-auto mb-5"
                max-height="300"
                max-width="450"
                min-height="30"
                min-width="270"
              >
              
                <v-col>
                  <v-img :src="'/images/gallery/'+theme.name+'.jpeg'">
                  </v-img>
                  <v-row class="justify-center align-center ma-0">
                    <h3 class="my-3"> {{ theme.name }}</h3>
                  </v-row>
                </v-col>
              </v-card>
            </v-hover>
        
          </v-col>
        </v-row>
    
      </v-card>
    </section>
    
    <Footer/>
  </v-app>
</template>

<script>
import {examples} from "@/router";
import TopNav from '@/components/TopNav'
import Footer from '@/components/Footer'
export default {
  name: "Home",
  components: {
    TopNav,
    Footer,
  },
  data: () => ({
    themes: examples,
    items: [
      {
        src: 'images/banner1.gif',
        to: '3d-editor'
      },
      {
        src: 'images/banner2.gif',
        to: 'marching-cubes'
      },
      {
        src: 'images/banner3.gif',
        to: 'shape-2d'
      },
    ],
    logoWidth: 100,
  }),
  mounted() {
    // this.logoWidth
    console.log('breakpoint' + this.$vuetify.breakpoint.width)
    this.logoWidth = Math.log2(this.$vuetify.breakpoint.width) * 8;
    
    
  },
  methods: {
    scrollToPosition(key) {
      document.querySelector('#' + key).scrollIntoView();
    }
  }
}
</script>

<style scoped>

</style>